<template>
	<div>
		<v-card dense outlined>
			<v-card-subtitle>
				<s-toolbar v-if="linesProduction.length == 0"
                    label="No existe proceso activo"
                    color="error"/> 
				<v-radio-group					
					row
					hide-details
					style="margin-top: -10px;"
					v-model="lineSelected">				
					<v-radio 
						v-for="line in linesProduction"
						:key="line.ClpID"
						:label="line.LncDescription"
						:value="line.ClpID"
					></v-radio>
				</v-radio-group>
			</v-card-subtitle>
			<v-card-text>
				<v-row>				
						
					
						<!-- <div class="containerLine">
							<div class="lineSide">
								<div v-for="table in itemsTablesOne" > 
									
									<div 
										class="stationWorkCpntFree" 
										:class="{ stationWorkCpntBusy: tableClickSelectedID == table.CttID, stationWorkCpntFree: tableClickSelectedID != table.CttID }"
										small
										@click="emitTable(table)"
									>
										{{ table.CttName }}
									</div>
									
								</div>
							</div>
							<div class="grafLine" v-if="showLine">
								
								
								
							</div>
							<div class="lineSide" >
								<div v-for="table in itemsTablesTwo"> 
									
									<div 
										class="stationWorkCpntFree" 
										:class="{ stationWorkCpntBusy: tableClickSelectedID == table.CttID, stationWorkCpntFree: tableClickSelectedID != table.CttID }"
										small
										@click="emitTable(table)"
									>
									
										{{ table.CttName }}
									</div>

								</div>
								
							</div>
						
				
							<div  style="position: absolute;top: 100%;left: 50%; transform: translate(-50%, -50%);" v-for="line in linesProduction">
								<v-chip
									small
									:color="line.CecColor">
									{{line.CecDescription}}
								</v-chip>
							</div>	
						</div>					 -->

						<div class="mesa-con-sillas" v-if="showLine">
							<div  class="textarea-container">
								<s-textarea 
									label="Comentario cambio de IQF"
									v-model="DescripcionIQF" 
									v-if="ShowDescripcionIQF">
									
								</s-textarea>
							</div>	
							<div class="contenedor">
								
								<div class="lado lado-derecha">										
									<div v-for="(table, index) in itemsTablesOne" :key="'izquierda-' + index" 
										class="silla" 
										:class="{ selectedsilla: tableClickSelectedID == table.CttID, silla: tableClickSelectedID != table.CttID }"
										@click="emitTable(table)">										
										<span class="namesilla">{{ table.CttName }}</span>
									</div>
								</div>
								<div class="mesa" :style="{ height: mesaAltura + 'px' }">
									<div class="tablero"></div>
									<div class="bastidor">
										<span class="texto-bastidor">{{this.TipeExit}}</span>
									</div>
								</div>
								<div class="lado lado-izquierda">
									<div v-for="(table, index) in itemsTablesTwo" :key="'izquierda-' + index" 
										class="silla" 
										:class="{ selectedsilla: tableClickSelectedID == table.CttID, silla: tableClickSelectedID != table.CttID }"
										@click="emitTable(table)">
										<span class="namesilla">{{ table.CttName }}</span>
									</div>
								</div>	
														
							</div>
						</div>					
				</v-row>
			</v-card-text>
		</v-card>
		
	</div>
</template>
<script>
	import('../../../styles/linesPacking.css');

	import _sFrzCtnLinesProcessService from "@/services/FrozenProduction/FrzCtnLinesProcess.js"
	import SText from '../../Utils/SText.vue';


	export default {
  		components: { SText },
		props: {
			filter: {
				type: Object,
				default: null
			},		
			detailsArmed: {
				type: Object,
				default: null
			},	
		},

		data() {
			return {
				itemsTablesOne:[],
        		itemsTablesTwo:[],
				linesProduction: [],
				lineSelected : 0,
				tables: [],
				tableClickSelectedID: 0,	
				showLine : false,	
				TipeExit: '',
				ShowDescripcionIQF: false,	
				DescripcionIQF: ''
			}
		},
		computed:{
			sillasDerecha() {
				// Divide las sillas en dos lados
				return Array.from({ length: Math.floor(this.itemsTablesOne.length) });
			},
			sillasIzquierda() {
				// Divide las sillas en dos lados
				return Array.from({ length: Math.ceil(this.itemsTablesTwo.length) });
			},
			mesaAltura()
			{
				// Calcula la altura de la mesa en función del número de sillas
				// Puedes ajustar el tamaño según el diseño deseado
			
				const alturaSilla = 40; // Altura de una silla (con márgenes)
				const espacioEntreSillas = 20; // Espacio entre sillas
				const margenMesa = 20; // Margen adicional para la mesa
				const totalSillas = Math.max(this.sillasDerecha.length, this.sillasIzquierda.length);
				
				return totalSillas * (alturaSilla + espacioEntreSillas) + margenMesa;
			}
		},
		watch: {
			lineSelected() {
				
				this.GetTableByLine();
				this.showLine = true;
			}
		},

		methods: {
			emitTable( table ){
				
				table.DescripcionIQF = this.DescripcionIQF;		
				this.$emit("emitTable", table)
				this.tableClickSelectedID = table.CttID;
				
			},
			getLinesProduction() {
				
				_sFrzCtnLinesProcessService
				.linesactive(  this.filter , this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{						
						this.linesProduction = resp.data;					
					}
				})
			},

			GetTableByLine()
			{
				this.tableClickSelectedID = 0;
				_sFrzCtnLinesProcessService
				.listTablesByLines({
					ClpID: this.lineSelected
				}, this.$fun.getUserID()).then(resp => {
					if(resp.status == 200){
						this.tables = resp.data;					
						this.itemsTablesOne = this.tables.filter(x => x.CttPosition == 1);					
                    	this.itemsTablesTwo = this.tables.filter(x => x.CttPosition == 2);
						this.TipeExit = this.itemsTablesOne[0].CecDescription;
						if(this.itemsTablesOne[0].CecID != this.detailsArmed.TypeProcess)
						{
							this.ShowDescripcionIQF = true;
							this.$fun.alert("Debe registrar el motivo de cambio de Tunel de Congelamiento", "warning")
							
						}else{
							this.ShowDescripcionIQF = false;
						}
					}
				})
			},

		},

		mounted () {
			console.log('detailsArmed', this.detailsArmed);
			this.getLinesProduction();		
		},
		created(){
			
		}
	}
</script>

<style>
.mesa-con-sillas {
	display: flex;
  	flex-direction: column;
}
.contenedor {
	display: flex;
	justify-content: space-between;
}
.mesa {
	flex: 2;
	display: flex;
	position: relative;
	width: 100px; 
	align-items: center;
	justify-content: center;
	background-color: #f0f0f0; 
	border: 3px solid #d1d1d1; 
	border-radius: 10px;
} 

.tablero {
	width: 100%;
	height: 60%;
	background-color: #4b8a3d;
	border-radius: 10px;
	position: absolute;
	top: 0;
}

.bastidor {
	width: 70px;
	height: 40px;
	background-color: #4b8a3d; 
	position: absolute;
	bottom: 0;
	left: calc(45% - 30px); 	
	display: flex;
  	align-items: center;
  	justify-content: center;
}

.texto-bastidor {
	color: #ffffff; 
	font-size: 12px; 
	font-weight: bold; 
	text-align: center; 
	margin: 5px; 
}

/* .lado {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 150px;
} */

.sillas {
  	display: flex;
  	justify-content: space-between; 
  	width: 440px;
}

.lado-izquierda {
  align-items: flex-start; 
}

.lado-derecha {
  align-items: flex-end; 
}

.silla {	
	
	display: flex;
	align-items: center; 
	justify-content: center; 
	padding: 10px;
	background-color: #4eca8a; 
	border: 2px solid #2a8a66;
	border-radius: 5px;
	margin: 10px;
	min-width: 80px; 
	min-height: 40px; 
	cursor: pointer;
	transition: background-color 0.3s, border-color 0.3s;
} 

.selectedsilla{
	background-color: #2a8a66; 
	border-color: #ffffff; 
}
.namesilla{
	text-align: center; 
  	font-size: 12px; 
  	color: #f0f0f0; 
} 

.textarea-container {
  
    margin-bottom: 20px; 
} 
</style>
